import React, { FC, useCallback } from 'react';

import { useHistory } from '../navigation/router';
import { routes } from '../../domain/routes';
import { ErrorPage } from '../../components/ErrorPage';
import { useTranslation } from 'react-i18next';

export const EmailVerificationErrorPage: FC = () => {
  const history = useHistory();
  const { t } = useTranslation('lang');

  const handleCheckBalance = useCallback(() => {
    history.push(routes.profile);
  }, [history]);

  return (
    <ErrorPage buttonTitle="Check Balance" onClick={handleCheckBalance}>
      <div className="l-row">
        <b>{t('verifyEmailError')}</b>
      </div>
      <div className="l-row">
        <svg className="icon icon--error">
          <use xlinkHref="/img/_icons.svg#icon--error" />
        </svg>
      </div>
    </ErrorPage>
  );
};
