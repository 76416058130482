import { updateCtx } from './interface-adapters/diContext';
import { createInstance, updateClientJwt } from './interface-adapters/client';
import { store } from './interface-adapters/store/store';
import { getToken } from './interface-adapters/auth/authStore';
import { logger, loggedRequest } from './interface-adapters/logger';

export const initDi = () => {
  // store needs to be initialized first since client have dependency on it
  updateCtx({
    store,
    logger: {
      error: logger.error,
      loggedRequest,
    },
  });
  const jwt = getToken(store.getState());

  updateCtx({
    client: {
      privateClient: createInstance(jwt || ''),
      publicClient: createInstance(),
      updateClientJwt,
    },
  });
};
