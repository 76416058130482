import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { isNull } from 'lodash';
import { persistReducer } from 'redux-persist';

import { getUserDataAPI, signInByPhoneAPI, validatePhoneOtpAPI } from '../api/auth';
import { JsonApiResponseDTO } from '../../boundries/responseDTO/jsonApi';
import { saveJsonApiResponseToDb, saveJsonApiResponseToDbFunction } from '../sharedSagas';
import { finishRequest, startRequest } from '../global/globalStore';
import {
  SetTokenActionPayload,
  SignInByPhoneActionPayload,
  ValidatePhoneOtpActionPayload,
} from '../../boundries/actionsPayloads/authActionPayloads';
import { generatePath } from '../navigation/router';
import { routes } from '../../domain/routes';
import { createActionWithHistory, ActionWithHistory } from '../actionWithHistory';
import storage from 'redux-persist/lib/storage';
import { AuthState } from '../../boundries/storeState/AuthState';
import { getLogin } from '../dbStore/dbStore';
import { LoginEntity } from '../../domain/entities/auth';
import { RootState } from '../../boundries/storeState/RootState';
import { getCtx } from '../diContext';
import { ClientResponse } from '../../boundries/client';
import { handleError } from '../error/utils';
import { toastr } from 'react-redux-toastr';

const STORE_KEY = 'auth';

const persistConfig = {
  key: STORE_KEY,
  storage,
};

const initialState: AuthState = {
  token: null,
};
const authSlice = createSlice({
  name: STORE_KEY,
  initialState,
  reducers: {
    setTokenValue: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { setTokenValue } = authSlice.actions;
const authSimpleReducer = authSlice.reducer;
export const authReducer = persistReducer(persistConfig, authSimpleReducer);

export const signIn = createActionWithHistory<SignInByPhoneActionPayload>('auth/signIn');
export const validatePhoneOtp =
  createActionWithHistory<ValidatePhoneOtpActionPayload>('auth/validatePhoneOtp');
export const updateUserData = createAction('auth/updateUserData');
export const setToken = createAction<SetTokenActionPayload>('auth/setToken');
export const logout = createAction('auth/logout');

const getStore = (store: RootState) => store.auth;
export const getToken = createSelector(getStore, (state) => state.token);
export const getIsAuthenticated = createSelector(getToken, (token) => !isNull(token));

function* signInSaga(action: PayloadAction<ActionWithHistory<SignInByPhoneActionPayload>>) {
  try {
    const {
      data: { phone },
      history,
    } = action.payload;
    yield put(startRequest());
    yield call(signInByPhoneAPI, phone);

    const path = generatePath(routes.signInvalidatePhoneOtp, {
      phoneNumber: phone,
    });
    yield call(history.push, path);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* validatePhoneSaga(
  action: PayloadAction<ActionWithHistory<ValidatePhoneOtpActionPayload>>,
) {
  try {
    const {
      data: { phone, otp },
      history,
    } = action.payload;
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<unknown>> = yield call(
      validatePhoneOtpAPI,
      phone,
      parseInt(otp, 10),
    );
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    const login: LoginEntity | undefined = yield select(getLogin);

    if (!login) throw Error(`login not found`);
    yield call(setTokenSaga, { payload: login.jwt, type: 'synt' });

    yield call(history.push, routes.profile);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* updateUserDataSaga() {
  try {
    const response: ClientResponse<JsonApiResponseDTO<unknown>> = yield call(getUserDataAPI);

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e);
  }
}

function* setTokenSaga(action: PayloadAction<SetTokenActionPayload>) {
  try {
    const {
      client: { updateClientJwt },
    } = getCtx();

    const jwt = action.payload;
    yield put(setTokenValue(jwt));
    yield call(updateClientJwt, jwt);
  } catch (e) {
    yield call(handleError, e);
  }
}

function* logoutSaga() {
  try {
    // @ts-ignore
    yield call(setTokenSaga, { payload: null });
    yield call(toastr.success, 'Done', 'Session cleared');
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* authSaga() {
  yield takeLatest(signIn, signInSaga);
  yield takeLatest(validatePhoneOtp, validatePhoneSaga);
  yield takeLatest(updateUserData, updateUserDataSaga);
  yield takeLatest(setToken, setTokenSaga);
  yield takeLatest(logout, logoutSaga);
}
