import React, { FC } from 'react';
import cn from 'classnames';

interface Props {
  isActive?: boolean;
}

export const Loader: FC<Props> = ({ isActive }) => {
  return (
    <div className={cn('c-loader', isActive && 'c-loader--active')}>
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </div>
  );
};
