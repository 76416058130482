import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isUndefined } from 'lodash';

import { routes } from '../../domain/routes';
import { Layout } from '../../components/Layout';
import { Button } from '../../components/Button';
import { getActivation, getUser } from '../dbStore/dbStore';
import { obfuscateEmail } from '../user/utils';
import { activateEmail } from './signupStore';
import { useAppDispatch } from '../store/hooks';
import { useTranslation } from 'react-i18next';

export const CheckInboxPage: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const activation = useSelector(getActivation);
  const { t } = useTranslation('lang');

  useEffect(() => {
    if (activation && activation.nextStep === 'emailActivation') {
      dispatch(activateEmail());
    }
  }, [activation, dispatch]);

  const handleCheckBalance = useCallback(() => {
    history.push(routes.profile);
  }, [history]);

  const user = useSelector(getUser);

  let email = !isUndefined(user) && !user.emailVerified ? obfuscateEmail(user.email) : '';

  return (
    <Layout
      footer={
        <footer className='l-footer'>
          <Button onClick={handleCheckBalance}>{t('profile')}</Button>
        </footer>
      }
    >
      <div className='l-row'>
        <div className='c-title'>{t('checkInboxTitle')} {email} {t('checkInboxTitleSecondary')}</div>
      </div>
      <div className='l-row'>
        <div className='c-text'>{t('checkInboxDescription')}</div>
      </div>
      <div className='l-row'>
        <svg className='icon icon--envelope'>
          <use xlinkHref='img/_icons.svg#icon--envelope' />
        </svg>
      </div>
    </Layout>
  );
};
