import React, { FC, MutableRefObject, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { getIsRequestInProgress } from '../global/globalStore';
import { useAppDispatch } from '../store/hooks';
import { inviteFriend } from './userSaga';
import { getReferral } from '../dbStore/dbStore';
import { generatePath } from '../navigation/router';
import { routes } from '../../domain/routes';
import { getEnv } from '../../config/env';
import { activationMode } from '../../config/signupConfig';
import { useTranslation } from 'react-i18next';

export const InviteFriendButton: FC = () => {
  const isLoading = useSelector(getIsRequestInProgress);
  const referral = useSelector(getReferral);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('lang');
  const handleSendInvitation = useCallback(() => {
    dispatch(inviteFriend());
  }, [dispatch]);
  const ref = useRef<HTMLInputElement | null>(null);
  const [copyText, setCopyText] = useState(t('copyURL'));

  const copyFromInput = (ref: MutableRefObject<HTMLInputElement | null>) => {
    if (ref.current) {
      const text_val = ref.current;
      text_val.focus();
      text_val.select();
      document.execCommand('copy');
      setCopyText(t('URLCopied'));
    }
  };

  const referralPath = activationMode === 'basic' ? routes.downloadCardByReferral : routes.signupTermsByReferral;

  const refUrl = new URL(
    generatePath(referralPath, {
      referralId: referral ? referral.refId : '1',
    }),
    getEnv().REACT_APP_URL,
  );

  if (isLoading) return <>Loading</>;

  if (!isUndefined(referral))
    return (
      <div className="c-copy">
        <input className="c-copy__type" type="text" value={refUrl.href} readOnly ref={ref} />
        <button className="c-copy__button c-button" type="button">
          <span className="c-copy__button--inner" onClick={() => copyFromInput(ref)}>
            {copyText}
          </span>
        </button>
      </div>
    );

  return (
    <button className="c-link" onClick={handleSendInvitation}>
      {t('inviteFriend')}
    </button>
  );
};
