import {
  ActionCreatorWithPayload,
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  SetEmailActionPayload,
  SetPhoneActionPayload,
  SetProfileActionPayload,
  StartActivationTermsActionPayload,
} from '../../boundries/actionsPayloads/signupActionPayloads';
import { createActionWithHistory } from '../actionWithHistory';

const signupSlice = createSlice({
  name: 'signup',
  initialState: 0,
  reducers: {
    increment: (state, action: PayloadAction<number>) => state + action.payload,
  },
});

export const signupReducer = signupSlice.reducer;

export const acceptTermsByUser: ActionCreatorWithPayload<StartActivationTermsActionPayload> =
  createAction('signup/acceptTermsByUser');

export const setProfile: ActionCreatorWithPayload<SetProfileActionPayload> =
  createAction('signup/setProfile');

export const skipProfile = createAction('signup/skipProfile');

export const setPhone: ActionCreatorWithPayload<SetPhoneActionPayload> =
  createAction('signup/setPhone');

export const activatePhone = createActionWithHistory('signup/activatePhone');

export const resendActivationCode = createAction('signup/resendActivationCode');
export const setEmail = createActionWithHistory<SetEmailActionPayload>('signup/setEmail');
export const skipEmail = createActionWithHistory('signup/skipEmail');
export const activateEmail = createAction('signup/activateEmail');
