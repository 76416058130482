import React, { FC, useEffect } from 'react';
import { isNull } from 'lodash';
import { useAppDispatch } from '../store/hooks';
import { getToken, setToken, updateUserData } from './authStore';
import { useSelector } from 'react-redux';

export const UpdateUserDataService: FC = ({ children }) => {
  const jwt = useSelector(getToken);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!isNull(jwt)) {
      dispatch(setToken(jwt));
      dispatch(updateUserData());
    }
  }, [jwt, dispatch]);

  return <>{children}</>;
};
