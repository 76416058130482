import { get, isUndefined } from 'lodash';
import { call } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { FormattedError } from '../../domain/entities/error';
import i18n from '../../i18n/i18n';

export function* handleError(e: Error) {
  const prettyError: unknown = get(e, 'response.data.data[0]');
  const status = get(e, 'response.status');

  if (status === 401) return;

  if (isFormattedError(prettyError)) {
    let errorTitle = i18n.t(prettyError.title, { ns: 'lang' }) || prettyError.title;
    let errorDetail = i18n.t(prettyError.detail, { ns: 'lang' }) || prettyError.detail;

    yield call(toastr.error, errorTitle, errorDetail);
  } else {
    yield call(toastr.warning, 'No connection', 'Please check connection');
    console.log(e);
  }
}

const isFormattedError = (data: unknown): data is FormattedError => {
  const code = get(data, 'response_code');

  return !isUndefined(code);
};
