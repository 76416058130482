import React, { FC, useCallback, useEffect } from 'react';
import { isNull } from 'lodash';

import { useHistory } from '../navigation/router';
import { routes } from '../../domain/routes';
import { SuccessPage } from '../../components/SuccessPage';
import { useQuery } from '../sharedHooks';
import { useAppDispatch } from '../store/hooks';
import { setToken } from '../auth/authStore';
import { InviteFriendButton } from '../user/InviteFriendButton';
import { useTranslation } from 'react-i18next';

export const TOKEN_KEY = 'token';
export const EmailVerificationSuccessPage: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useQuery();
  const token = params.get(TOKEN_KEY);
  const { t } = useTranslation('lang');

  const handleCheckBalance = useCallback(() => {
    history.push(routes.profile);
  }, [history]);

  useEffect(() => {
    if (!isNull(token)) {
      dispatch(setToken(token));
    }
  }, [token, dispatch]);

  return (
    <SuccessPage buttonTitle={t('profile')} onClick={handleCheckBalance}>
      <div className="l-row">
        <b>{t('emailConfirmedTitle')}</b>
      </div>
      <div className="l-row">
        <div className="c-text">{t('emailConfirmedDescription')}</div>
      </div>
      <div className="l-row">
        <div className="c-line" />
      </div>
      <div className="l-row">
        <b>{t('referralProgram')}</b>
      </div>
      <div className="l-row l-row--mt-16">
        <b>{t('referralProgramTitle')}</b>
        <div className="c-text">
          {t('referralProgramDescription')}
        </div>
      </div>
      <div className="l-row">
        <InviteFriendButton />
      </div>
    </SuccessPage>
  );
};
