import React, { FC, useState } from 'react';
import PhoneInputComponent, { PhoneInputProps } from 'react-phone-number-input';

import getCountriesLocale from './countriesLocale';
import './phone-styles.css';

export const PhoneInput: FC<PhoneInputProps> = (props) => {

  const [countriesLocale, setCountriesLocale] = useState({});
  getCountriesLocale.then(data => setCountriesLocale(data.default));

  return <div className="c-phone">
    <PhoneInputComponent
      placeholder="Enter phone number"
      inputClassName="c-phone__input"
      defaultCountry="UA"
      flagUrl="/img/flags/{XX}.svg"
      international
      labels={countriesLocale}
      {...props}
    />
  </div>

}
