import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getBalance } from '../dbStore/dbStore';
import { isActivationModeBasic } from './utils';
import { useTranslation } from 'react-i18next';

export const BonusesBalance: FC = () => {
  const balance = useSelector(getBalance);
  const { t } = useTranslation('lang');

  if (isActivationModeBasic()) {
    return <span data-id="balance-title">{t('discountAmount')} {balance?.balance || 0}{t('discountItem')}</span>;
  }
  return null;
};
