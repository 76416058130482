import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { store } from './store/store';
import { LogErrorActionPayload } from '../boundries/actionsPayloads/loggerActionPayloads';
import { handleError } from './error/utils';
import { logErrorAPI } from './api/logger';
import { serializeError } from 'serialize-error';

const logErrorAction = createAction<LogErrorActionPayload>('logger/logError');

function* logErrorSaga(action: PayloadAction<LogErrorActionPayload>) {
  try {
    const data = action.payload;
    yield call(logErrorAPI, data);
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* loggerSaga() {
  yield takeLatest(logErrorAction, logErrorSaga);
}

const error = (error: string, component: string, e?: Error) => {
  const { dispatch } = store;
  const serializedError = serializeError(e);
  const fullStackTrace = serializedError?.stack || '';
  const actionPayload = {
    error,
    message: fullStackTrace,
    component,
  };

  dispatch(logErrorAction(actionPayload));
};

export const logger = {
  error,
};

export const loggedRequest = function* (
  loggerData: { componentName: string; error: string },
  API: any,
  ...options: any[]
) {
  const correlationId = uuidv4();
  try {
    yield call(API, ...options);
  } catch (e) {
    const serializedError = serializeError(e);
    const fullStackTrace = serializedError?.stack || '';

    yield call(
      logErrorAPI,
      {
        error: loggerData.error,
        component: loggerData.componentName,
        message: fullStackTrace,
      },
      correlationId,
    );
  }
};
