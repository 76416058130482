import { FC, useState } from 'react';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { CrossButton } from '../../components/CrossButton';
import { Layout } from '../../components/Layout';
import { routes } from '../../domain/routes';
import { useHistory } from '../navigation/router';
import { changeLang } from './userSaga';
import { useDispatchWithHistory } from '../actionWithHistory';
import { useTranslation } from 'react-i18next';

export const ChangeLanguagePage: FC = () => {
  const history = useHistory();
  const dispatch = useDispatchWithHistory();
  const { t, i18n } = useTranslation('lang');
  const currentLanguage = i18n.language;

  const [lang, setLang] = useState(currentLanguage);
  const langsArr = ['ua', 'en']; //@todo take from from different place

  const handleBack = () => {
    history.push(routes.profile);
  };

  const saveLang = () => {
    dispatch(changeLang, { lang });
  }

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={saveLang}>
            {t('update')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <CrossButton onClick={handleBack} />

      <div className="l-row">
        <div className="c-title">{t('changeLanguage')}</div>
      </div>
      <div className="l-row">
        <select className="c-select" value={lang} onChange={(e) => setLang(e.target.value)}>
          {langsArr.map(lang => <option key={lang}>{lang}</option>)}
        </select>
      </div>
    </Layout>
  );
};
