import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from "./en.json";
import ua from "./ua.json";

export const i18nInit = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      resources: {
        en: {
          lang: en
        },
        ua: {
          lang: ua
        },
      },
      supportedLngs: ['en', 'ua'],
    });
}

export default i18n;
