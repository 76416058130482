import React, { FC } from 'react';
import cn from 'classnames';
import { ButtonProps } from './types';

export const Button: FC<ButtonProps> = ({ title, isDisabled, isLoading, children, ...rest }) => (
   <button
     className={cn(rest['data-display'] === 'link' ? 'c-link' : 'c-button', isLoading && 'c-button--has-loader')}
     type="button"
     disabled={isDisabled}
     {...rest}
   >
     <span className={cn(rest['data-display'] === 'link' ? 'c-link__inner' : 'c-button__inner')}>{title || children}</span>
     <span className="c-loader">
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </span>
   </button>
 )