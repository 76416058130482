import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes } from '../../domain/routes';
import { DevListPage } from '../../DevListPage';
import { DownloadCardPage } from '../card/DownloadCardPage';
import { SignInPage } from '../auth/SignInPage';
import { ValidateOtpPage } from '../auth/ValidateOtpPage';
import { DownloadCardByReferralPage } from '../card/DownloadCardByReferralPage';
import { ProfilePage } from '../signup/ProfilePage';
import { TermsPage as SignupTermsPage } from '../signup/TermsPage';
import { ActivationUser } from '../user/ActivationUser';

export const PublicNavigation = () => {
  return (
    <Switch>
      <Route path={routes.dev} exact>
        <DevListPage />
      </Route>

      <Route path={routes.activationUser}>
        <ActivationUser />
      </Route>

      <Route path={routes.signupProfile}>
        <ProfilePage />
      </Route>

      <Route path={routes.signupTermsByReferral}>
        <SignupTermsPage />
      </Route>

      <Route path={routes.signupTerms}>
        <SignupTermsPage />
      </Route>

      <Route path={routes.downloadCard}>
        <DownloadCardPage />
      </Route>

      <Route path={routes.downloadCardByReferral}>
        <DownloadCardByReferralPage />
      </Route>

      <Route path={routes.signIn} exact>
        <SignInPage />
      </Route>

      <Route path={routes.signInvalidatePhoneOtp}>
        <ValidateOtpPage />
      </Route>

      <Route path="*">
        <SignInPage />
      </Route>
    </Switch>
  );
};
