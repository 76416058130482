import React, { FC, useEffect, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';

import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { CrossButton } from '../../components/CrossButton';
import { useHistory } from '../navigation/router';
import { routes } from '../../domain/routes';
import { getProfile } from '../dbStore/dbStore';
import { changeProfile } from './userSaga';
import { useDispatchWithHistory } from '../actionWithHistory';
import { isNameValid } from './utils';
import { useTranslation } from 'react-i18next';

export const ChangeProfilePage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const history = useHistory();
  const profile = useSelector(getProfile);
  const { t } = useTranslation('lang');
  const [firstName, setFirstName] = useState(!isUndefined(profile) ? profile.firstName : '');
  const [lastName, setLastName] = useState(!isUndefined(profile) ? profile.lastName : '');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  useEffect(() => {
    if (!isEmpty(firstName) && !isNameValid(firstName)) {
      setFirstNameError(t('firstNameInvalid'));
    } else {
      setFirstNameError('');
    }
  }, [firstName, t]);

  useEffect(() => {
    if (!isEmpty(lastName) && !isNameValid(lastName)) {
      setLastNameError(t('lastNameInvalid'));
    } else {
      setLastNameError('');
    }
  }, [lastName, t]);

  const canUpdate = !isEmpty(firstName) && !isEmpty(lastName);
  const updateName = () => {
    dispatch(changeProfile, {
      firstName,
      lastName,
    });
  };

  const handleBack = () => {
    history.push(routes.profile);
  };

  const firstNameValidationClass = !isEmpty(firstNameError) ? 'c-input--validation' : '';
  const lastNameValidationClass = !isEmpty(lastNameError) ? 'c-input--validation' : '';

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={updateName} isDisabled={!canUpdate}>
            {t('update')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <CrossButton onClick={handleBack} />
      <div className="l-row">
        <div className="c-title">{t('profileUpdatePersonalInfo')}</div>
      </div>
      <div className="l-row">
        <div className={`c-input ${firstNameValidationClass}`}>
          <div className="c-input__message c-input__message--error">{firstNameError}</div>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <span className="c-input__title">{t('setProfileFirstName')}</span>
          </label>
        </div>
      </div>
      <div className="l-row">
        <div className={`c-input ${lastNameValidationClass}`}>
          <div className="c-input__message c-input__message--error">{lastNameError}</div>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <span className="c-input__title">{t('setProfileLastName')}</span>
          </label>
        </div>
      </div>
    </Layout>
  );
};
