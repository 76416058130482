import { Store } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { merge } from 'lodash';
import { isEmpty, get, isUndefined } from 'lodash';
import { RecursivePartial } from '../boundries/utilityTypes';

interface DiContext {
  client: {
    publicClient: AxiosInstance;
    privateClient: AxiosInstance;
    updateClientJwt: (token: string) => void;
  };
  store: Store;
  logger: {
    error: (error: string, component: string, e?: Error) => void;
    loggedRequest: (
      loggerData: { componentName: string; error: string },
      API: any,
      ...options: any[]
    ) => Iterable<any>;
  };
}

// This will throw runtime error if some try to use uninitialized, but will
// allow destruct in the global context
let ctx: DiContext | {} = {};

export const getCtx = () => {
  if (isEmpty(ctx)) throw Error('Context is not initialized');

  return ctx as DiContext;
};

export const setCtx = (newCtx: DiContext) => {
  ctx = newCtx;
};

export const updateCtx = (newCtx: RecursivePartial<DiContext>) => {
  ctx = merge(ctx, newCtx);
};

export const getDependency = <T>(dependencyName: keyof DiContext): T => {
  const dependency = get(ctx, dependencyName);
  if (isUndefined(dependency)) throw Error('Context is not initialized');

  return dependency;
};
