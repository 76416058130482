import { isFirefox, isIOS } from "react-device-detect";
import { isNil } from "lodash";

import { getEnv } from '../config/env';
import { subscribeToNotificationsAPI } from './api/user';
import { GetPushNotificationDTO } from '../boundries/responseDTO/notificationsApi';

const isNotificationsSupported = () => !isIOS && !isNil(Notification);

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
const applicationServerPublicKey = getEnv().REACT_APP_PUSH_SERVER_PUBLIC_KEY || '';
const applicationServerKey = urlBase64ToUint8Array(applicationServerPublicKey);

export const subscribeToPushManager = () => {

  if(!isNotificationsSupported()) {
    console.log('Notifications are not supported');
    return;
  }

  if (!isNotificationsViewed()) {
    navigator?.serviceWorker?.getRegistration().then(reg => {
      reg?.pushManager?.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      }).then(sub => {
        const subscription: PushSubscriptionJSON = sub.toJSON();
        if (!subscription.endpoint) return false;
        subscribeToNotificationsAPI(subscription);
      }).catch(error => {
        console.error('PushManager subscribe error: ', error);
      });
    });
  }
};

const displayNotification = (notification: GetPushNotificationDTO, registration: ServiceWorkerRegistration) => {
    const options = {
      body: notification.msg,
      icon: "favicon.ico",
      vibrate: [200, 100, 200, 100, 200, 100, 400],
      // data: { primaryKey: 1 },
      // actions: [
      //   {action: 'go', title: 'YES', icon: "images/yes.png"},
      //   {action: 'close', title: 'NO', icon: "images/no.png"},
      // ]
    };
    registration?.showNotification(notification.title, options);
};

// self.addEventListener('notificationclose', (event) => {
//   var notification = event.notification;
//   var primaryKey = notification.data.primaryKey;

//   console.log('Closed notification: ' + primaryKey);
// });

// self.addEventListener('notificationclick', (event) => {
//   const notification = event.notification;
//   const primaryKey = event.notification.data.primaryKey;
//   const action = event.action;
//   if (action === 'close') {
//     notification.close();
//   } else {
//     console.log(primaryKey);
//   }
// });

export const NotificationShow = (notification: GetPushNotificationDTO, registration: ServiceWorkerRegistration): boolean => {
  if (!isNotificationsSupported() || !isNotificationsViewed()) {
    return false;
  }

  if (isNotificationsAccepted()) {
    displayNotification(notification, registration);
  } else {
    Notification.requestPermission(() => {
      if (isNotificationsAccepted()) {
        displayNotification(notification, registration);
      } else {
        console.log('Notifications denied');
      }
    });
  }
  return true;
};

export const isNotificationsAccepted = () => (
  isNotificationsSupported() && Notification.permission === 'granted'
);

export const isNotificationsRejected = () => (
  isNotificationsSupported() && Notification.permission === 'denied'
);

export const isNotificationsViewed = () => (
  isNotificationsSupported() && Notification.permission !== 'default'
);

export const isUserActionForPushSubscriptionNeeded = () => (
  isFirefox
);
