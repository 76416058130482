import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../store/hooks';
import { activatePhone, resendActivationCode } from './signupStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { getUser } from '../dbStore/dbStore';
import { ValidateOtpPage } from '../../components/pages/ValidateOtpPage';
import { useDispatchWithHistory } from '../actionWithHistory';

export const PhoneActivationPage: FC = () => {
  const dispatch = useAppDispatch();
  const dispatchWithHistory = useDispatchWithHistory();
  useRedirectOnAuthStepChangeEffct();
  const user = useSelector(getUser);
  const phoneNumber = user?.phone || '';
  const sendCode = (otp: string) => {
    dispatchWithHistory(activatePhone, { otp });
  };

  const resendActivationFn = () => {
    dispatch(resendActivationCode());
  };


  return (
    <ValidateOtpPage
      onResendActivationCode={resendActivationFn}
      onSendOtpClick={sendCode}
      phoneNumber={phoneNumber}
    />
  );
};
