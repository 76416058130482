import React, { FC, useState, useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { Layout } from '../../components/Layout';
import { useAppDispatch } from '../store/hooks';
import { setProfile, skipProfile } from './signupStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { useSelector } from 'react-redux';
import { getActivation } from '../dbStore/dbStore';
import { useParams } from '../navigation/router';
import { isNameValid } from '../user/utils';
import { useTranslation } from 'react-i18next';

export const ProfilePage: FC = () => {
  const activation = useSelector(getActivation);
  const dispatch = useAppDispatch();
  const params = useParams<{ userId?: string }>();
  const { t } = useTranslation('lang');
  useRedirectOnAuthStepChangeEffct();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  useEffect(() => {
    if (!isEmpty(firstName) && !isNameValid(firstName)) {
      setFirstNameError(t('firstNameInvalid'));
    } else {
      setFirstNameError('');
    }
  }, [firstName, t]);

  useEffect(() => {
    if (!isEmpty(lastName) && !isNameValid(lastName)) {
      setLastNameError(t('lastNameInvalid'));
    } else {
      setLastNameError('');
    }
  }, [lastName, t]);

  let userId = params.userId;

  const canSetProfile = !isEmpty(firstName) && !isEmpty(lastName);
  const next = () => {
    dispatch(
      setProfile({
        firstName,
        lastName,
        userId
      }),
    );
  };

  const handleOnSkipProfileCLick = useCallback(() => {
    dispatch(skipProfile());
  }, [dispatch]);

  const firstNameValidationClass = !isEmpty(firstNameError) ? 'c-input--validation' : '';
  const lastNameValidationClass = !isEmpty(lastNameError) ? 'c-input--validation' : '';

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={next} isDisabled={!canSetProfile}>
            {t('continue')}
          </ButtonWithLoading>
          { activation && activation.nextStepSkippable &&
          <div className="l-row l-row--mt-16">
            <button className="c-link" onClick={handleOnSkipProfileCLick}>
              {t('skip')}
            </button>
          </div>
          }
        </footer>
      }
    >
      <button className="c-close" type="button">
        <span className="c-close__inner">
          <svg className="icon icon--cross">
            <use xlinkHref="img/_icons.svg#icon--cross" />
          </svg>
        </span>
      </button>
      <div className="l-row">
        <div className="c-title">{t('setProfileTitle')}</div>
      </div>
      <div className="l-row">
        <div className={`c-input ${firstNameValidationClass}`}>
          <div className="c-input__message c-input__message--error">{firstNameError}</div>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <span className="c-input__title">{t('setProfileFirstName')}</span>
          </label>
        </div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className={`c-input ${lastNameValidationClass}`}>
          <div className="c-input__message c-input__message--error">{lastNameError}</div>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <span className="c-input__title">{t('setProfileLastName')}</span>
          </label>
        </div>
      </div>
      <div className="l-row">{t('setProfileCardPreview')}</div>
      <div className="l-row l-row--mt-16">
        <div className="c-card">
          <div className="c-card__name">
            {firstName} {lastName}
          </div>
          <div className="c-card__number">1234 - 1234 - 1234</div>
        </div>
      </div>
    </Layout>
  );
};
