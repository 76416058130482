import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  acceptTermsByUser,
  activatePhone,
  resendActivationCode,
  setPhone,
  setProfile,
  skipProfile,
  setEmail,
  skipEmail,
  activateEmail,
} from './signupStore';
import {
  acceptTermsAPI,
  activatePhoneAPI,
  resendActivationCodeAPI,
  setPhoneAPI,
  setProfileAPI,
  skipProfileAPI,
  setEmailAPI,
  skipEmailAPI,
  activateEmailAPI, skipEmailActivationAPI,
} from '../api/signup';
import {
  ActivatePhoneActionPayload,
  SetPhoneActionPayload,
  SetProfileActionPayload,
  StartActivationTermsActionPayload,
  SetEmailActionPayload,
} from '../../boundries/actionsPayloads/signupActionPayloads';
import { saveJsonApiResponseToDb, saveJsonApiResponseToDbFunction } from '../sharedSagas';
import { JsonApiResponseDTO } from '../../boundries/responseDTO/jsonApi';
import { ActionWithHistory } from '../actionWithHistory';
import { routes } from '../../domain/routes';
import { setToken } from '../auth/authStore';
import { ClientResponse } from '../../boundries/client';
import { handleError } from '../error/utils';
import { finishRequest, startRequest } from '../global/globalStore';
import { ActivationEntity } from '../../domain/entities/signup';

function* acceptTermsByUserSaga(action: PayloadAction<StartActivationTermsActionPayload>) {
  try {
    const referralId = action.payload.referralId;
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<ActivationEntity>> =
      yield call(acceptTermsAPI, referralId);

    const token = response.data.included[0].attributes.token;

    if(token){
      yield put(setToken(token));
    }

    yield call<saveJsonApiResponseToDbFunction<ActivationEntity>>(
      saveJsonApiResponseToDb,
      response.data,
    );

  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* setProfileSaga(action: PayloadAction<SetProfileActionPayload>) {
  try {
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      setProfileAPI,
      action.payload,
    );

    const token = response.data.included[0].attributes.token;

    if(token){
      yield put(setToken(token));
    }

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* skipProfileSaga() {
  try {
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(skipProfileAPI);
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* setPhoneSaga(action: PayloadAction<SetPhoneActionPayload>) {
  try {
    const phoneNumber = action.payload;
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      setPhoneAPI,
      phoneNumber,
    );
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* activatePhoneSaga(action: PayloadAction<ActionWithHistory<ActivatePhoneActionPayload>>) {
  try {
    const phoneNumber = action.payload.data.otp;
    const history = action.payload.history;
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      activatePhoneAPI,
      parseInt(phoneNumber, 10),
    );

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    yield call(history.push, routes.signupPhoneActivationSuccess);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* resendActivationCodeSaga() {
  try {
    yield put(startRequest());
    yield call(resendActivationCodeAPI);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* setEmailSaga(action: PayloadAction<ActionWithHistory<SetEmailActionPayload>>) {
  try {
    const {
      data: { email },
      history,
    } = action.payload;
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(setEmailAPI, email);
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    yield call(history.push, routes.signupCheckInbox);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* skipEmailSaga(action: PayloadAction<ActionWithHistory<{}>>) {
  try {
    const {
      history,
    } = action.payload;
    yield put(startRequest());
    const responseSkipEmail: ClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(skipEmailAPI);
    const responseEmailActivation: ClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(skipEmailActivationAPI);
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, responseSkipEmail.data);
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, responseEmailActivation.data);
    yield call(history.push, routes.profile);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

function* activateEmailSaga() {
  try {
    yield put(startRequest());
    const response: ClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(activateEmailAPI);
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e);
  } finally {
    yield put(finishRequest());
  }
}

export function* signupSaga() {
  yield takeLatest(acceptTermsByUser, acceptTermsByUserSaga);
  yield takeLatest(setProfile, setProfileSaga);
  yield takeLatest(skipProfile, skipProfileSaga);
  yield takeLatest(setPhone, setPhoneSaga);
  yield takeLatest(activatePhone, activatePhoneSaga);
  yield takeLatest(resendActivationCode, resendActivationCodeSaga);
  yield takeLatest(setEmail, setEmailSaga);
  yield takeLatest(skipEmail, skipEmailSaga);
  yield takeLatest(activateEmail, activateEmailSaga);
}
