import { SignupStep } from '../../domain/entities/signup';
import { routes } from '../../domain/routes';
import i18n from '../../i18n/i18n';

export const signupPageByState: Record<SignupStep, string> = {
  [SignupStep.Basic]: routes.signupTerms,
  [SignupStep.Profile]: routes.signupProfile,
  [SignupStep.SetPhone]: routes.signupSetPhone,
  [SignupStep.PhoneActivation]: routes.signupPhoneActivation,
  [SignupStep.PhoneActivationSuccess]: routes.signupPhoneActivationSuccess,
  [SignupStep.SetEmail]: routes.signupSetEmail,
  [SignupStep.EmailActivation]: routes.signupCheckInbox,
  [SignupStep.Completed]: routes.profile,
};

export const signupStepsLabels = {
  [SignupStep.Basic]: "",
  [SignupStep.Profile]: i18n.t('setProfile',{ns:'lang'}),
  [SignupStep.SetPhone]: "",
  [SignupStep.PhoneActivation]: "",
  [SignupStep.PhoneActivationSuccess]: "",
  [SignupStep.SetEmail]: i18n.t('verifyYourEmail',{ns:'lang'}),
  [SignupStep.EmailActivation]: "",
  [SignupStep.Completed]: i18n.t('profile',{ns:'lang'}),
}
