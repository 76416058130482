import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getActivation } from '../../interface-adapters/dbStore/dbStore';
import { signupPageByState, signupStepsLabels } from '../../interface-adapters/signup/consts';

export const OptionalActivationStepsButton = () => {

  const history = useHistory();
  const activation = useSelector(getActivation);
  const nextStep = activation?.nextStep;   //todo TS
  const nextStepLabel = signupStepsLabels[nextStep || 'basic'];

  const handleClickButton = useCallback(() => {
    if (!nextStep) return;  //todo TS remove
    const redirectUrl = signupPageByState[nextStep];
    history.push(redirectUrl);
  }, [history, nextStep]);

  return (
    <>
      { !activation?.completed &&
      <button className='c-link' data-id="next-step-button" onClick={handleClickButton}>
        {nextStepLabel}
      </button>
      }
    </>
  );
};
