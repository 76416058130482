import React from 'react';
import { useSelector } from 'react-redux';

import './App.css';
import { Link } from './interface-adapters/navigation/router';
import { getUserId } from './interface-adapters/dbStore/dbStore';
import { routes } from './domain/routes';
import { useAppDispatch } from './interface-adapters/store/hooks';
import { logout } from './interface-adapters/auth/authStore';
import { sendPush } from './interface-adapters/user/userSaga'; 

export const DevListPage = () => {
  const dispatch = useAppDispatch();
  const userId = useSelector(getUserId);
  
  const onSendPush = () => {
    if (userId) {
      dispatch(sendPush({
        userId,
        title: "Push title",
        message: "Push BODY"
      }));
    }
  };

  return (
    <div>
      <h1>Developers Mode, Warning, Here be dragons!</h1>
      <ol style={{ textAlign: 'left', lineHeight: '2' }}>
        <li>
          <Link to={routes.signIn}>Log In</Link>
        </li>
        <li>
          <Link to={routes.downloadCard}>Loyalty Program</Link>
        </li>
        <li>
          <Link to={routes.profile}>Your Account</Link>
        </li>
        <li>
          <Link to={routes.downloadCard}>Download Card</Link>
        </li>
        <li>
          <Link to={routes.downloadCardByReferral}>Download Card by Ref</Link>
        </li>
        <li>
          <Link to={routes.signupTerms}>Start signup</Link>
        </li>
        <li>
          <button onClick={() => onSendPush()}>Send push notification</button>
        </li>
        <li>
          <button onClick={() => dispatch(logout())}>Logout</button>
        </li>
      </ol>
    </div>
  );
};
