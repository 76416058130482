import React from 'react';
import { Provider } from 'react-redux';
import { Navigation } from './interface-adapters/navigation/Navigation';
import { Router } from './interface-adapters/navigation/router';
import { persistor, store } from './interface-adapters/store/store';
import { UpdateUserDataService } from './interface-adapters/auth/UpdateUserDataService';
import ReduxToastr from 'react-redux-toastr';
import { PersistGate } from 'redux-persist/integration/react';

import './App.css';
import './scss/_style.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { DIBootstrap } from './interface-adapters/global/DIBootstrap';
import { LogUnhandledErrorsService } from './interface-adapters/error/LogUnhandledErrorsService';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DIBootstrap>
          <LogUnhandledErrorsService>
            <Router>
              <UpdateUserDataService>
                <Navigation />
              </UpdateUserDataService>
            </Router>

            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              preventDuplicates
              position="top-left"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
          </LogUnhandledErrorsService>
        </DIBootstrap>
      </PersistGate>
    </Provider>
  );
}

export default App;
