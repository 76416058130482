import { envProps } from '../boundries/config';

export const getEnv = (): envProps => ({
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_URL: process.env.REACT_APP_URL,
  REACT_APP_PUSH_SERVER_PUBLIC_KEY: process.env.REACT_APP_PUSH_SERVER_PUBLIC_KEY,
  ISSUER_SYS_NAME: 'bi',
  DEFAULT_LOCALE: 'ua',  //en or ua
});

