import { call, put, takeLatest } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { initDi } from '../../initDi';
import { finishInitializeClient } from './globalStore';

function* initDISaga() {
  try {
    yield call(initDi);
    yield put(finishInitializeClient());
  } catch (e) {
    console.log(e);
  }
}

export function* globalSaga() {
  yield takeLatest(REHYDRATE, initDISaga);
}
