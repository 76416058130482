import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { routes } from '../../domain/routes';
import { DevListPage } from '../../DevListPage';
import { ProfilePage as Profile } from '../user/ProfilePage';
import { ChangePhonePage } from '../user/ChangePhonePage';
import { ChangePhoneActivationPage } from '../user/ChangePhoneActivationPage';
import { ChangeEmailPage } from '../user/ChangeEmailPage';
import { ChangeProfilePage } from '../user/ChangeProfilePage';
import { getActivation } from '../dbStore/dbStore';
import { EmailVerificationPage } from '../signup/EmailVerificationPage';
import { PhoneActivationSuccessPage } from '../signup/PhoneActivationSuccessPage';
import { EmailVerificationSuccessPage } from '../signup/EmailVerificationSuccessPage';
import { EmailVerificationErrorPage } from '../signup/EmailVerificationErrorPage';
import { SetPhonePage } from '../signup/SetPhonePage';
import { PhoneActivationPage } from '../signup/PhoneActivationPage';
import { CheckInboxPage } from '../signup/CheckInboxPage';
import { ProfilePage } from '../signup/ProfilePage';
import { InviteFriendPage } from '../user/InviteFriendPage';
import { TermsPage as SignupTermsPage } from '../signup/TermsPage';
import { ChangeLanguagePage } from '../user/ChangeLanguage';

const routesAvailableAfterRequiredSteps = [
  <Route path={routes.profile} exact key={routes.profile}>
    <Profile />
  </Route>,
  <Route path={routes.profileChangePhone} key={routes.profileChangePhone}>
    <ChangePhonePage />
  </Route>,
  <Route path={routes.profileChangePhoneActivation} key={routes.profileChangePhoneActivation}>
    <ChangePhoneActivationPage />
  </Route>,
  <Route path={routes.profileChangeEmail} key={routes.profileChangeEmail}>
    <ChangeEmailPage />
  </Route>,
  <Route path={routes.profileChangeName} key={routes.profileChangeName}>
    <ChangeProfilePage />
  </Route>,
  <Route path={routes.profileChangeLanguage} key={routes.profileChangeLanguage}>
    <ChangeLanguagePage />
  </Route>,
  <Route path={routes.profileInviteFriend} key={routes.profileInviteFriend}>
    <InviteFriendPage />
  </Route>,
];
export const PrivateNavigation = () => {
  const activation = useSelector(getActivation);
  const isSignRequiredStepsComplete = activation?.nextStepSkippable || activation?.completed;

  return (
    <Switch>
      {isSignRequiredStepsComplete && routesAvailableAfterRequiredSteps.map((child) => child)}

      <Route path={routes.dev} exact>
        <DevListPage />
      </Route>
      <Route path={routes.signupTerms}>
        <SignupTermsPage />
      </Route>
      <Route path={routes.signupProfile}>
        <ProfilePage />
      </Route>
      <Route path={routes.signupSetPhone}>
        <SetPhonePage />
      </Route>
      <Route path={routes.signupPhoneActivation}>
        <PhoneActivationPage />
      </Route>
      <Route path={routes.signupPhoneActivationSuccess}>
        <PhoneActivationSuccessPage />
      </Route>
      <Route path={routes.signupSetEmail}>
        <EmailVerificationPage />
      </Route>
      <Route path={routes.signupCheckInbox}>
        <CheckInboxPage />
      </Route>
      <Route path={routes.signupVerifyEmailSuccess}>
        <EmailVerificationSuccessPage />
      </Route>
      <Route path={routes.signupVerifyEmailError}>
        <EmailVerificationErrorPage />
      </Route>

      <Route path='*'>
        <Profile />
      </Route>
    </Switch>
  );
};
