import {
  SignInByPhoneRequestDTO,
  ValidatePhoneOtpRequestDTO,
} from '../../boundries/requestDTO/auth';
import { getCtx } from '../diContext';

export const signInByPhoneAPI = (phoneNumber: string) => {
  const {
    client: { publicClient },
  } = getCtx();

  const payload: SignInByPhoneRequestDTO = {
    data: {
      attributes: {
        phone: phoneNumber,
      },
    },
  };
  return publicClient.post(`/user/loginByPhone`, payload);
};

export const validatePhoneOtpAPI = (phoneNumber: string, code: number) => {
  const {
    client: { publicClient },
  } = getCtx();

  const payload: ValidatePhoneOtpRequestDTO = {
    data: {
      attributes: {
        phone: phoneNumber,
        code,
      },
    },
  };

  return publicClient.post(`/user/loginByPhone/check`, payload);
};

export const getUserDataAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.get(`/user/me`);
};
