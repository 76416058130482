import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { getActivation } from './dbStore/dbStore';
import { SignupStep } from '../domain/entities/signup';
import { signupPageByState } from './signup/consts';

export const useRedirectOnAuthStepChangeEffct = () => {
  const activation = useSelector(getActivation);
  const history = useHistory();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<SignupStep>(SignupStep.Basic);

  useEffect(() => {
    //@todo think of better logic to not redirect user from 'success phone activation' page
    if (location.pathname === '/signup/phone-activation') return;

    if (location.pathname === '/profile' && activation?.nextStepSkippable) return;

    if (!activation) return;

    if (activation.completed || activation.nextStep !== currentStep) {
      setCurrentStep(activation.nextStep);
      const redirectUrl = signupPageByState[activation.nextStep];
      history.push(redirectUrl);
    }
  }, [activation, currentStep, history, location.pathname]);
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
