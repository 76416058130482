import React, { FC, useState } from 'react';

import { Layout } from '../../components/Layout';
import { Link } from '../../interface-adapters/navigation/router';
import { useParams } from '../navigation/router';
import { useAppDispatch } from '../store/hooks';
import { acceptTermsByUser } from './signupStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { routes } from '../../domain/routes';
import { useTranslation } from 'react-i18next';

export const TermsPage: FC = () => {
  const { referralId } = useParams<{ referralId?: string | undefined }>();
  const dispatch = useAppDispatch();
  useRedirectOnAuthStepChangeEffct();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { t } = useTranslation('lang');

  const acceptFn = () => {
      dispatch(acceptTermsByUser({referralId}));
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={acceptFn} isDisabled={!termsAccepted}>
            {t('createAccount')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">{t('termsTitle')}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">
          {t('termsDescription')}
        </div>
      </div>
      <div className="l-row l-row--mt-80">
        <div className="c-checkbox">
          <label className="c-checkbox__label">
            <input
              type="checkbox"
              name="checkbox"
              hidden
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <span className="c-checkbox__icon" />
            <span className="c-checkbox__title">
              {t('termsCheckboxTitle')}
              <Link to={routes.terms}>
                {t('termsCheckboxLink')}
              </Link>
              {t('termsCheckboxTitleSecondary')}
            </span>
          </label>
        </div>
      </div>
    </Layout>
  );
};