import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes } from '../../domain/routes';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import { getCard, getProfile } from '../dbStore/dbStore';
import { getBalanceHistory, getUserCard } from './userSaga';
import { useAppDispatch } from '../store/hooks';
import { getIsAuthenticated } from '../auth/authStore';
import { downloadIOSCardAction, downloadAndroidCardAction } from '../card/cardStore';
import { InviteFriendButton } from './InviteFriendButton';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import {
  subscribeToPushManager,
  isNotificationsViewed,
  isUserActionForPushSubscriptionNeeded,
} from '../subscriptionNotifications';
import { OptionalActivationStepsButton } from '../../components/OptionalActivationStepsButton/OptionalActivationStepsButton';
import { DiscountAmount } from './DiscountAmount';
import { BonusesBalance } from './BonusesBalance';
import { isUserAndroid, isUserIos } from './utils';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import { ButtonDownloadCard } from '../card/ButtonDownloadCard';

const isWithPushButton = isUserActionForPushSubscriptionNeeded() && !isNotificationsViewed();

export const ProfilePage: FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const profile = useSelector(getProfile);
  const isAuth = useSelector(getIsAuthenticated);
  const card = useSelector(getCard);
  const { t } = useTranslation('lang');
  const isPageReady = isUndefined(profile);

  const firstName: string = profile?.firstName || '',
    lastName: string = profile?.lastName || '';

  useRedirectOnAuthStepChangeEffct();

  useEffect(() => {
    if (!isWithPushButton) {
      subscribeToPushManager();
    }
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(getUserCard());
    }
  }, [isAuth, dispatch]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getBalanceHistory());
    }
  }, [isAuth, dispatch]);

  const downloadIOSCard = useCallback(
    (e) => {
      dispatch(downloadIOSCardAction());
    },
    [dispatch],
  );

  const downloadAndroidCard = useCallback(
    (e) => {
      dispatch(downloadAndroidCardAction());
    },
    [dispatch],
  );

  const handleOnUpdateProfileCLick = useCallback(() => {
    history.push(routes.profileChangeName);
  }, [history]);

  const handleOnUpdatePhoneCLick = useCallback(() => {
    history.push(routes.profileChangePhone);
  }, [history]);

  const handleOnUpdateEmailCLick = useCallback(() => {
    history.push(routes.profileChangeEmail);
  }, [history]);

  const handleOnChangeLanguageCLick = useCallback(() => {
    history.push(routes.profileChangeLanguage);
  }, [history]);

  const handleOnSubscribeCLick = useCallback(() => {
    subscribeToPushManager();
  }, []);

  return (
    <Layout>
      {isPageReady && <Loader isActive={true} />}
      {!isPageReady && (
        <>
          <div className="l-row l-row--mt-16">
            <div className="c-title c-title--fs-14">
              {t('hello')}
              {firstName ? `, ${firstName}` : ''}
            </div>
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-text">{t('profileDescription')}</div>
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-line" />
          </div>
          <div className="l-row l-row--mt-16">
            <DiscountAmount />
            <BonusesBalance />
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-card">
              <div className="c-card__name">
                {firstName} {lastName}
              </div>
              <div className="c-card__number">{card?.cardNumber}</div>
            </div>
          </div>

          <div className="l-row l-row--mt-16 c-download-buttons">
            <ButtonDownloadCard data-id="download-card-link"
                               onClick={downloadIOSCard}
                               device='iOS'
                               data-display={isUserIos() ? "button" : "link"}>
              {t('downloadCard')} {t('forIOS')}
            </ButtonDownloadCard>

            <ButtonDownloadCard data-id="download-card-link"
                               onClick={downloadAndroidCard}
                               device='android'
                                data-display={isUserAndroid() ? "button" : "link"}>
              {t('downloadCard')} {t('forAndroid')}
            </ButtonDownloadCard>

          </div>

          <div className="l-row l-row--mt-16">
            <div className="c-get">
              <div className="l-row">
                <b>{t('referralProgram')}</b>
              </div>
              <div className="l-row l-row--mt-16">
                <b>{t('referralProgramTitle')}</b>
                <div className="c-text">{t('referralProgramDescription')}</div>
              </div>
              <div className="l-row l-row--mt-16">
                <InviteFriendButton />
              </div>
            </div>
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="update-name-card-link"
              onClick={handleOnUpdateProfileCLick}
            >
              {t('updateNameOnCard')}
            </button>
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="update-phone-number-link"
              onClick={handleOnUpdatePhoneCLick}
            >
              {t('updatePhoneNumber')}
            </button>
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="update-email-link"
              onClick={handleOnUpdateEmailCLick}
            >
              {t('updateEmail')}
            </button>
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="change-language-link"
              onClick={handleOnChangeLanguageCLick}
            >
              {t('changeLanguage')}
            </button>
          </div>
          <div className="l-row l-row--mt-16">
            <OptionalActivationStepsButton />
          </div>
          {isWithPushButton && (
            <div className="l-row l-row--mt-16">
              <button className="c-link c-link--blue" onClick={handleOnSubscribeCLick}>
                {t('subscribeNotifications')}
              </button>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};
