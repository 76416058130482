import React, { FC } from 'react';

import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { useDispatchWithHistory } from '../actionWithHistory';
import { CrossButton } from '../../components/CrossButton';
import { useHistory } from '../navigation/router';
import { routes } from '../../domain/routes';
import { changeEmail } from './userSaga';
import { useForm } from 'react-hook-form';
import { Input } from '../forms/Input';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';

interface FormData {
  email: string;
}

const validateOptions = {
  required: i18n.t('emailRequired', { ns: 'lang' }),
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: i18n.t('emailInvalid', { ns: 'lang' }),
  },
};

export const ChangeEmailPage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const history = useHistory();
  const { t } = useTranslation('lang');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    dispatch(changeEmail, {
      email: data.email,
    });
  };

  const handleBack = () => {
    history.push(routes.profile);
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={handleSubmit(onSubmit)}>{t('update')}</ButtonWithLoading>
        </footer>
      }
    >
      <CrossButton onClick={handleBack} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="l-row">
          <div className="c-title">{t('updateEmailTitle')}</div>
        </div>
        <div className="l-row">
          <Input
            label="email"
            initFormOption={register('email', validateOptions)}
            error={errors.email}
          />
        </div>
      </form>
    </Layout>
  );
};
