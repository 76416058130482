import React, { FC, useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';

import { setEmail, skipEmail } from './signupStore';
import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { isEmailValid } from '../user/utils';
import { useDispatchWithHistory } from '../actionWithHistory';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { useSelector } from 'react-redux';
import { getActivation } from '../dbStore/dbStore';
import { useTranslation } from 'react-i18next';

export const EmailVerificationPage: FC = () => {
  const activation = useSelector(getActivation);
  const dispatchWithHistory = useDispatchWithHistory();
  const [mail, setMail] = useState('');
  const [mailError, setMailError] = useState('');
  const { t } = useTranslation('lang');

  useRedirectOnAuthStepChangeEffct();

  useEffect(() => {
    if (!isEmpty(mail) && !isEmailValid(mail)) {
      setMailError(t('emailInvalid'));
    } else {
      setMailError('');
    }
  }, [mail, t]);

  const canVerifyEmail = !isEmpty(mail);
  const sendConfirmation = () => {
    dispatchWithHistory(setEmail, {
      email: mail,
    });
  };


  const handleOnSkipEmailCLick = useCallback(() => {
    dispatchWithHistory(skipEmail, {});
  }, [dispatchWithHistory]);

  const mailValidationClass = !isEmpty(mailError) ? 'c-input--validation' : '';

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={sendConfirmation} isDisabled={!canVerifyEmail}>
            {t('sendConfirmation')}
          </ButtonWithLoading>
          {activation && activation.nextStepSkippable &&
          <div className="l-row l-row--mt-16">
            <button className="c-link" onClick={handleOnSkipEmailCLick}>
              {t('skip')}
            </button>
          </div>
          }
        </footer>
      }
    >
      <button className="c-close" type="button">
        <span className="c-close__inner">
          <svg className="icon icon--cross">
            <use xlinkHref="img/_icons.svg#icon--cross" />
          </svg>
        </span>
      </button>
      <div className="l-row">
        <div className="c-title">{t('verifyEmailTitle')}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">
          {t('verifyEmailDescription')}
        </div>
      </div>
      <div className="l-row">
        <div className={`c-input ${mailValidationClass}`}>
          <div className="c-input__message c-input__message--error">{mailError}</div>
          <label className="c-input__label">
            <input
              className="c-input__type"
              type="text"
              placeholder=" "
              value={mail}
              onChange={(event) => setMail(event.target.value)}
            />
            <span className="c-input__title">{t('Email')}</span>
          </label>
        </div>
      </div>
    </Layout>
  );
};
