import { AxiosResponse } from 'axios';

import { CreateCardRequestDTO } from '../../boundries/requestDTO/card';
import { getCtx } from '../diContext';
import { getEnv } from '../../config/env';

const downloadCardFromPromise = (response: AxiosResponse) => {
  const reader = new FileReader();
  reader.onload = function(e) {
    // @ts-ignore
    window.location.href = reader.result;
  };
  reader.readAsDataURL(response.data);
};

export const createCardAPI = () => createCardByReferralAPI('id');

export const createCardByReferralAPI = (referral_id: string) => {
  const {
    client: { publicClient },
  } = getCtx();

  const payload: CreateCardRequestDTO = {
    data: {
      attributes: {
        issuer: getEnv().ISSUER_SYS_NAME,
        referral_id,
      },
    },
  };

  return publicClient
    .post(`/card/create`, payload, { responseType: 'blob', timeout: 15000 })
    .then(downloadCardFromPromise);
};

export const getUserCardAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.get('/card/my');
};

export const downloadIOSCardAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient
    .get(`/card/ios/download`, { responseType: 'blob', timeout: 15000 })
    .then(downloadCardFromPromise);
};

export const downloadAndroidCardAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient
    .get(`/card/android`);
};

export const getReferralAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.get(`/referral`);
};

export const getBalanceHistoryAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.get('/card/balanceHistory');
};
