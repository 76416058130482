import React, { FC, useState } from 'react';
import { isEmpty } from 'lodash';

import { Layout } from '../../components/Layout';
import { PhoneInput } from '../../components/PhoneInput';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { signIn } from './authStore';
import { useDispatchWithHistory } from '../actionWithHistory';
import { Link } from '../navigation/router';
import { signUpStartRoute } from '../../config/signupConfig';
import { phoneNumberMaxLength } from '../../config/appConfig';
import { useTranslation } from 'react-i18next';

export const SignInPage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const [phoneNumber, setPhoneNumber] = useState('');
  const { t } = useTranslation('lang');

  const canSignIn = !isEmpty(phoneNumber);
  const handleSignIn = () => {
    dispatch(signIn, { phone: phoneNumber });
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={handleSignIn} isDisabled={!canSignIn}>
            {t('sendConfirmationCode')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">{t('loginTitle')}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">{t('loginWithPhoneNumber')}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <PhoneInput maxLength={phoneNumberMaxLength} onChange={setPhoneNumber} value={phoneNumber} />
      </div>
      <div className="l-row l-row--mt-16">
        <Link to={signUpStartRoute}>
          <button className="c-link">
            {t('signupLink')}
          </button>
        </Link>
      </div>
    </Layout>
  );
};
