import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCard } from '../dbStore/dbStore';
import { isActivationModeStandard } from './utils';
import { useTranslation } from 'react-i18next';

export const DiscountAmount: FC = () => {
  const card = useSelector(getCard);
  const { t } = useTranslation('lang');
  if(isActivationModeStandard()){
    return <span data-id="discount-title">{t('discountAmount')} {card?.discountPercent || 0 }{t('discountItem')}</span>
  }
  return null;
};
