import { getCtx } from '../diContext';
import { LogErrorRequestDTO } from '../../boundries/requestDTO/logget';
import { LogErrorActionPayload } from '../../boundries/actionsPayloads/loggerActionPayloads';

function toIsoString(date: Date) {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = (num: number) => {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  );
}

export const logErrorAPI = (data: LogErrorActionPayload, correlation_id = 'unknown') => {
  const {
    client: { publicClient },
  } = getCtx();

  const dt = new Date();
  const payload: LogErrorRequestDTO = {
    correlation_id,
    env: 'develop',
    // develop
    // production
    level: 'error',
    payload: data,
    service_name: 'loyalty-pwa',
    timestamp: toIsoString(dt),
  };

  return publicClient.post(`/logger/addMessage`, payload);
};
