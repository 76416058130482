import React, { FC } from 'react';

import { Layout } from '../../components/Layout';
import { InviteFriendButton } from './InviteFriendButton';
import { useTranslation } from 'react-i18next';

export const InviteFriendPage: FC = () => {
  const { t } = useTranslation('lang');
  return (
    <Layout>
      <div className="l-row">
        <div className="c-title">Invite your friend and earn 5 bonuses</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="l-row l-row--mt-16">
          <div className="c-get">
            <div className="l-row">
              <b>{t('issuerTitle')} referral program</b>
            </div>
            <div className="l-row l-row--mt-16">
              <div className="c-text">
                When new client install {t('issuerTitle')} loyalty card and create new account both you and
                your friend will earn 5 bonuses.
              </div>
            </div>
            <div className="l-row l-row--mt-16">
              <InviteFriendButton />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
