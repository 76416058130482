import {
  PhoneChangeRequestDTO,
  EmailChangeRequestDTO,
  ValidateNewPhoneOtpRequestDTO,
  ProfileChangeRequestDTO,
  PushSubscriptionDTO,
  PushNotificationDTO,
} from '../../boundries/requestDTO/user';
import { getCtx } from '../diContext';

export const changePhoneAPI = (phoneNumber: string) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: PhoneChangeRequestDTO = {
    data: {
      attributes: {
        phone: phoneNumber,
      },
    },
  };
  return privateClient.post(`/user/phone/change`, payload);
};

export const changeEmailAPI = (email: string) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: EmailChangeRequestDTO = {
    data: {
      attributes: {
        email: email,
      },
    },
  };
  return privateClient.post(`/user/mail/change`, payload);
};

export const validateNewPhoneOtpAPI = (code: number) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: ValidateNewPhoneOtpRequestDTO = {
    data: {
      attributes: {
        code,
      },
    },
  };

  return privateClient.post(`/user/phone/activation/check`, payload);
};

export const changeProfileAPI = (firstName: string, lastName: string) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: ProfileChangeRequestDTO = {
    data: {
      attributes: {
        firstName: firstName,
        lastName: lastName,
      },
    },
  };
  return privateClient.put(`/user/profile`, payload);
};

export const subscribeToNotificationsAPI = (subscription: PushSubscriptionJSON) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: PushSubscriptionDTO = {
    data: {
      attributes: {...subscription},
    },
  };

  return privateClient.post(`/notification/webPush/subscribe`, payload);
};

export const sendPushNotificationAPI = (userId: string, title: string, message: string) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: PushNotificationDTO = {
    data: {
      attributes: {
        title,
        msg: message,
      },
    },
  };

  return privateClient.post(`/notification/webPush/${userId}/send`, payload);
};
