import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useHistory } from '../navigation/router';
import { SuccessPage } from '../../components/SuccessPage';
import { getActivation } from '../dbStore/dbStore';
import { signupPageByState, signupStepsLabels } from './consts';
import { useTranslation } from 'react-i18next';

export const PhoneActivationSuccessPage: FC = () => {
  const history = useHistory();
  const { t } = useTranslation('lang');

  const activation = useSelector(getActivation);
  const nextStep = activation?.nextStep;
  const nextStepLabel = signupStepsLabels[nextStep || 'completed'];

  const handleNextStep = useCallback(() => {
    if (!nextStep) return;
    const redirectUrl = signupPageByState[nextStep];
    history.push(redirectUrl);
  }, [history, nextStep]);

  return (
    <SuccessPage title={t('phoneActivationSuccessTitle')} buttonTitle={nextStepLabel} onClick={handleNextStep}>
      <div className="l-row">
        <div className="c-text">
          {t('phoneActivationSuccessDesc')}
          <br />
          {t('phoneActivationSuccessDesc2')}
        </div>
      </div>
      <div className="l-row">
        <b>{t('phoneActivationSuccessTitle2')}</b>
      </div>
      <div className="l-row">
        <div className="c-line" />
      </div>
      <div className="l-row">
        <b>{t('phoneActivationSuccessTitle3')}</b>
      </div>
      <div className="l-row l-row--mt-16">
        <b>{t('phoneActivationSuccessProgram')}</b>
        <div className="c-text">{t('phoneActivationSuccessCompleteReg')}</div>
      </div>
    </SuccessPage>
  );
};
