import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { logout } from './auth/authStore';
import { getCtx, updateCtx } from './diContext';
import { getEnv } from '../config/env';

export const createInstance = (token?: string) => {
  const {
    store,
    logger: { error: logError },
  } = getCtx();

  let config: AxiosRequestConfig = {
    baseURL: getEnv().REACT_APP_API_URL,
    timeout: 4000,
    headers: {"x-issuer-sys-name": getEnv().ISSUER_SYS_NAME}
  };

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const instance = axios.create(config);

  const responseHandler = (response: AxiosResponse) => {
    if (response.status === 401) {
      store.dispatch(logout());
    }

    return response;
  };

  const errorHandler = (error: AxiosError) => {
    if (error.response?.status === 500) {
      logError('500 error', 'unknown', error);
    }

    if (error.response?.status === 401) {
      store.dispatch(logout());
    }

    return Promise.reject(error);
  };

  instance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error),
  );

  return instance;
};

export const updateClientJwt = (token: string) => {
  const privateClient = createInstance(token);
  updateCtx({ client: { privateClient } });
};
