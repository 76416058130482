import { Redirect } from 'react-router-dom';

import { useParams } from '../navigation/router';
import { activationUserRedirection } from '../../config/signupConfig';

export const ActivationUser = () => {
  const params = useParams<{ userId?: string }>();
  const redirectionPath = `${activationUserRedirection}/${params.userId}`;
  return <Redirect to={redirectionPath} />
};
