import {
  PhoneActivateRequestDTO,
  SetPhoneTermsRequestDTO,
  SetProfileRequestDTO,
  StartActivationTermsRequestDTO,
  SetEmailRequestDTO,
} from '../../boundries/requestDTO/signup';
import { getCtx } from '../diContext';
import { activationMode } from '../../config/signupConfig';

export const acceptTermsAPI = (referralId?: string) => {
  const {
    client: { publicClient },
  } = getCtx();

  const payload: StartActivationTermsRequestDTO = {
    data: {
      attributes: {
        agreement: true,
      },
    },
  };

  if (referralId) {
    payload.data.attributes.ref_id = referralId;
  }

  //@todo refactor with BE to remove this conditional code
  let url = '';
  switch (activationMode) {
    case 'basic':
      url = `/user/activation/${activationMode}/terms`;
      break;
    case 'standard':
      url = `/user/registration/${activationMode}/start/terms`;
      break;
  }

  return publicClient.post(url, payload);
};

export const setProfileAPI = (data: { firstName: string; lastName: string; userId?: string }) => {

  const {
    client: { privateClient },
  } = getCtx();

  const payload: SetProfileRequestDTO = {
    data: {
      attributes: { firstName: data.firstName, lastName: data.lastName },
    },
  };

  let url = '';
  switch (activationMode) {
    case 'basic':
      url = `/user/${data.userId}/activation/${activationMode}/start/profile`;
      break;
    case 'standard':
      url = `/user/activation/${activationMode}/profile`;
      break;
  }

  return privateClient.post(url, payload);
};

export const skipProfileAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.post(`/user/activation/${activationMode}/skip/profile`);
};

export const setPhoneAPI = (phoneNumber: string) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: SetPhoneTermsRequestDTO = {
    data: {
      attributes: {
        phone: phoneNumber,
      },
    },
  };

  return privateClient.post(`/user/activation/${activationMode}/setPhone`, payload);
};

export const activatePhoneAPI = (code: number) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: PhoneActivateRequestDTO = {
    data: {
      attributes: {
        code,
      },
    },
  };
  return privateClient.post(`/user/activation/${activationMode}/phoneActivation`, payload);
};

export const resendActivationCodeAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.post(`/user/phone/activation/resend`);
};

export const setEmailAPI = (email: string) => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload: SetEmailRequestDTO = {
    data: {
      attributes: {
        email: email,
      },
    },
  };
  return privateClient.post(`/user/activation/${activationMode}/setEmail`, payload);
};

export const skipEmailAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.post(`/user/activation/${activationMode}/skip/setEmail`);
};

export const skipEmailActivationAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  return privateClient.post(`/user/activation/${activationMode}/skip/emailActivation`);
};

export const activateEmailAPI = () => {
  const {
    client: { privateClient },
  } = getCtx();

  const payload = {
    data: {
      attributes: {},
    },
  };

  return privateClient.post(`/user/activation/${activationMode}/emailActivation`, payload);
};
